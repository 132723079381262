declare global {
   interface HTMLCanvasElement {
     captureStream(frameRate?: number): MediaStream;
  }
}

export class Effects {
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    video: HTMLVideoElement;
    private should_draw: boolean = false;
    constructor() {
        // prepare our DOM elements
        this.canvas = document.createElement('canvas');
        this.ctx = <CanvasRenderingContext2D> this.canvas.getContext('2d');
        this.video = document.createElement('video');
        // a flag to know if we should keep drawing on the canvas or not
        this.should_draw = true;

        // no need for audio there
        this.video.muted = true;
        // gUM video tracks can change size
        this.video.onresize = (evt) => {
          this.canvas.width = this.video.videoWidth;
          this.canvas.height = this.video.videoHeight;
        };
        // in case users blocks the camera?
        this.video.onpause = (evt) => {
          this.should_draw = false;
        };
        this.video.onplaying = (evt) => {
          this.should_draw = true;
          this.drawVideoToCanvas();
        };
    }

    public insertStream(original_stream: MediaStream) {
        this.video.srcObject = original_stream;
    
        this.video.play();

        const canvas_track = this.canvas.captureStream().getVideoTracks()[0];
        const originalStop = canvas_track.stop.bind(canvas_track);
        // override the #stop method so we can revoke the camera stream
        canvas_track.stop = () => {
          originalStop();
          this.should_draw = false;
          original_stream.getVideoTracks()[0].stop();
        };

        // merge with audio tracks
        return new MediaStream( original_stream.getAudioTracks().concat( canvas_track ) );
    }

    // the drawing loop
    public drawVideoToCanvas = () => {
      if(!this.should_draw) {
        return;
      }
      //this.ctx.filter = "none";
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.filter = "saturate(0%)";
      this.ctx.drawImage(this.video,0,0);
      requestAnimationFrame( this.drawVideoToCanvas );
    }
}
